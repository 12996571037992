<template>
    <b-card title="Create FAQs">
        <validation-observer ref="simpleRules">
            <b-form @submit.prevent>
                <b-row>
                    <!-- Question -->
                    <b-col cols="12">
                        <b-form-group label="Question" label-for="question">
                            <validation-provider name="Question" rules="required|max:20" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="UserIcon" />
                                    </b-input-group-prepend>

                                    <b-form-input id="question" v-model="question"
                                        :state="errors.length > 0 ? false : null" placeholder="Question" />
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- Answer -->
                    <b-col cols="12">
                        <b-form-group label="Answer" label-for="Answer">
                            <validation-provider name="answer" rules="required" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="BoxIcon" />
                                    </b-input-group-prepend>
                                    <!-- <b-form-input id="fh-Answer" v-model="Answer" type="Answer"
                                        :state="errors.length > 0 ? false : null" placeholder="Answer" /> -->
                                        <b-form-textarea id="answer" v-model="answer" 
                                        :state="errors.length > 0 ? false : null" placeholder="Enter something..." rows="5" size="lg" max-rows="10"></b-form-textarea>
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- submit and reset -->
                    <b-col>
                        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit"
                            @click.prevent="validationForm" variant="primary" class="mr-1">
                            Submit
                        </b-button>
                        <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="outline-secondary"
                            @click="reset">
                            Reset
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
    </b-card>
</template>

<script>
import {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BFormText,
    BFormDatalist,
    BFormInvalidFeedback,
    BInputGroup,
    BInputGroupPrepend,
    BFormValidFeedback,
    BFormFile,
    BFormSelect,
    BFormTextarea,
    BImg
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
//Database
import { db, storage } from "@/main";
import firebase from "firebase";
import axios from 'axios';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'


//Vee-Validate 
import { ValidationProvider, ValidationObserver, validate } from 'vee-validate';
import {
    required, email, confirmed, password, image, size, unique
} from '@validations'

export default {
    components: {
        BCard,
        BRow,
        BCol,
        BFormGroup,
        BInputGroup,
        BInputGroupPrepend,
        BFormInput,
        BFormCheckbox,
        BForm,
        BFormText,
        BButton,
        BFormDatalist,
        BFormInvalidFeedback,
        BFormValidFeedback,
        BFormFile,
        BFormSelect,
        BFormTextarea,
        BImg,
        ValidationProvider,
        ValidationObserver,
        validate,
        required,
        email,
        confirmed,
        password,
        image,
        size,
        unique
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            question: '',
            answer: '',
        }
    },
    created() {
        this.getCountries();
    },
    computed: {
        validation() {
            return this.question.length > 4 && this.question.length < 13
        },
    },
    methods: {
        reset() {
            this.question = '',
            this.answer = '';
        },

        validationForm() {

            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    // eslint-disable-next-line
                    this.submit();
                }
            })
        },

        onImageChange(event) {

            validate(event.target.files[0], 'required|image|size:100', {
                name: 'Profile Image',
            }).then(result => {
                if (result.valid) {
                    const fileReader = new FileReader();

                    fileReader.onload = (event) => {
                        this.previewImg = event.target.result;
                    }
                    fileReader.readAsDataURL(event.target.files[0]);
                } else {
                    this.previewImg = '';
                }
            });
        },

       submit() {
            store.dispatch('app/commitActivateLoader');
             var date = new Date().toLocaleString();//.toISOString().slice(0, 10);
                                db.collection("faqs")
                                .add({
                                    question: this.question,
                                    answer: this.answer,
                                    created: date,
                                    modified: date,
                                })
                                .then((doc) => {
                                    console.log("Added Successfully!");
                                            store.dispatch('app/commitDeactivateLoader');
                                            this.$router.push({ name: 'faq-index' });
                                })
        },
        showNotification(title, icon, variant) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title,
                    icon,
                    variant,
                },
            })
        },
    },
}
</script>